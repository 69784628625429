import './App.css';
import ParticlesComponent from './particles';
import pic from './vivian_circle.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <body className="App-body"> 
        <div className="App-ui">
          <img src={pic} alt="viv" />
        </div>
       <div className="App-background">     
        <ParticlesComponent />
        </div>
      </body>
    </div>
  );
}

export default App;
